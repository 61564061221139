<template>
  <b-card>
    <b-overlay
      :show="data === null"
      rounded="sm"
    >
      <b-form
        v-if="data"
        class="mt-1"
      >
        <b-row>
          <b-col
            cols="6"
            md="3"
          >
            <b-form-group
              :label="'By product title'"
              label-for="search_by_title"
            >
              <b-form-checkbox
                id="search_by_title"
                v-model="data.search_by_title"
                :state="errors && errors.search_by_title ? false : null"
              />

              <b-form-invalid-feedback v-if="errors && errors.search_by_title">
                {{ errors.search_by_title[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            md="3"
          >
            <b-form-group
              :label="'By product keywords'"
              label-for="search_by_keywords"
            >
              <b-form-checkbox
                id="search_by_keywords"
                v-model="data.search_by_keywords"
                :state="errors && errors.search_by_keywords ? false : null"
              />

              <b-form-invalid-feedback v-if="errors && errors.search_by_keywords">
                {{ errors.search_by_keywords[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            md="3"
          >
            <b-form-group
              :label="'By product SKU'"
              label-for="search_by_sku"
            >
              <b-form-checkbox
                id="search_by_sku"
                v-model="data.search_by_sku"
                :state="errors && errors.search_by_sku ? false : null"
              />

              <b-form-invalid-feedback v-if="errors && errors.search_by_sku">
                {{ errors.search_by_sku[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            md="3"
          >
            <b-form-group
              :label="'By product EAN'"
              label-for="search_by_ean"
            >
              <b-form-checkbox
                id="search_by_ean"
                v-model="data.search_by_ean"
                :state="errors && errors.search_by_ean ? false : null"
              />

              <b-form-invalid-feedback v-if="errors && errors.search_by_ean">
                {{ errors.search_by_ean[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            md="3"
          >
            <b-form-group
              :label="'By category'"
              label-for="search_by_category_title"
            >
              <b-form-checkbox
                id="search_by_category_title"
                v-model="data.search_by_category_title"
                :state="errors && errors.search_by_category_title ? false : null"
              />

              <b-form-invalid-feedback v-if="errors && errors.search_by_category_title">
                {{ errors.search_by_category_title[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            md="3"
          >
            <b-form-group
              :label="'By attributes title'"
              label-for="search_by_attributes_title"
            >
              <b-form-checkbox
                id="search_by_attributes_title"
                v-model="data.search_by_attributes_title"
                :state="errors && errors.search_by_attributes_title ? false : null"
              />

              <b-form-invalid-feedback v-if="errors && errors.search_by_attributes_title">
                {{ errors.search_by_attributes_title[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            md="3"
          >
            <b-form-group
              :label="'Init relations'"
              label-for="search_init_relations"
            >
              <b-form-checkbox
                id="search_init_relations"
                v-model="data.search_init_relations"
                :state="errors && errors.search_init_relations ? false : null"
              />

              <b-form-invalid-feedback v-if="errors && errors.search_init_relations">
                {{ errors.search_init_relations[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            md="3"
            class="hidden"
          >
            <b-form-group
              :label="'Init history'"
              label-for="search_init_history"
            >
              <b-form-checkbox
                id="search_init_history"
                v-model="data.search_init_history"
                :state="errors && errors.search_init_history ? false : null"
              />

              <b-form-invalid-feedback v-if="errors && errors.search_init_history">
                {{ errors.search_init_history[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            md="3"
          >
            <b-form-group
              :label="'Init GPT'"
              label-for="search_gpt_status"
            >
              <b-form-checkbox
                id="search_gpt_status"
                v-model="data.search_gpt_status"
                :state="errors && errors.search_gpt_status ? false : null"
              />

              <b-form-invalid-feedback v-if="errors && errors.search_gpt_status">
                {{ errors.search_gpt_status[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <b-col
            v-show="data.search_gpt_status"
            cols="12"
          >
            <b-form-group
              :label="'GPT Token'"
              label-for="search_gpt_token"
            >
              <b-form-input
                id="search_gpt_token"
                v-model="data.search_gpt_token"
                :state="errors && errors.search_gpt_token ? false : null"
              />

              <b-form-invalid-feedback v-if="errors && errors.search_gpt_token">
                {{ errors.search_gpt_token[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row
          v-if="$ability.can('update', 'settings')"
          class="mt-2"
        >
          <b-col
            cols="12"
          >
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="submit"
            >
              {{ $t('general.save') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BForm, BButton, BOverlay, BFormGroup, BFormInvalidFeedback, BFormInput, BCard, BFormCheckbox,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    BRow, BCol, BForm, BButton, BOverlay, BFormGroup, BFormInvalidFeedback, BFormInput, BCard, BFormCheckbox,
  },
  data() {
    return {
      data: null,
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  async beforeCreate() {
    await this.$http('/api/administration/settings', { params: { group: 'search' } }).then(response => {
      this.data = response.data
    })
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    async submit() {
      await this.$http.put('/api/administration/settings/search', this.data)
        .then(async () => {
          this.$store.dispatch('validation/clearErrors')

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.successfully'),
              text: this.$t('notifications.success.updated'),
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
        .catch(async error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              text: error.data.message,
              icon: 'CoffeeIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>
